import React, { useState, useEffect } from "react";
import styles from "../styles/ChatHistory.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

interface ChatHistoryProps {
  onSelectHistory: (messages: { text: string; isUser: boolean }[]) => void; // Callback to load selected chat history
  handleNewConversation: () => void; // Callback to load selected chat history
}

const ChatHistory: React.FC<ChatHistoryProps> = ({
  onSelectHistory,
  handleNewConversation,
}) => {
  const [history, setHistory] = useState<
    {
      id: string;
      title: string;
      messages: { text: string; isUser: boolean }[];
    }[]
  >([]);

  // Load history from localStorage on component mount
  useEffect(() => {
    const savedHistory = localStorage.getItem("chatHistory");
    if (savedHistory) {
      setHistory(JSON.parse(savedHistory));
    }
  }, []);

  // Handle deleting a specific history
  const handleDeleteHistory = (id: string) => {
    const updatedHistory = history.filter((item) => item.id !== id);
    setHistory(updatedHistory);
    localStorage.setItem("chatHistory", JSON.stringify(updatedHistory));
  };

  return (
    <div className={styles.historyPanel}>
      <div className={styles.wrapTitleButton}>
        <h2>Chat History</h2>
        <button
          onClick={() => {
            handleNewConversation();
          }}
          className={styles.newButton}
        >
          <FontAwesomeIcon icon={faPenToSquare} /> {/* Send icon */}
        </button>
      </div>
      {history.length === 0 ? (
        <p>No saved chats yet.</p>
      ) : (
        <ul className={styles.historyList}>
          {history.map((item) => (
            <li key={item.id} className={styles.historyItem}>
              <div onClick={() => onSelectHistory(item.messages)}>
                {item.title}
              </div>
              {item.id !== "current" && (
                <button
                  onClick={() => handleDeleteHistory(item.id)}
                  className={styles.deleteButton}
                >
                  Delete
                </button>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ChatHistory;
