import React, { useState, useEffect, useRef } from "react";
import ChatInput from "./ChatInput";
import ChatMessage from "./ChatMessage";
import Loader from "./Loader";
import { fetchOpenAIResponse } from "../services/openaiService";
import styles from "../styles/ChatWindow.module.css";
import { calculateTokenCount, truncateMessages } from "../utils/helpers";
import ChatHistory from "./ChatHistory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

const ChatWindow: React.FC = () => {
  const content =
    "You are a helpful assistant. Never respond with more than 300 words. Try always to limit the respond, for saving the costs but do never compensate the quality of the respond. If not asked to respond in other languages, respond always in faroese";
  const [messages, setMessages] = useState<{ text: string; isUser: boolean }[]>(
    []
  ); // Chat messages
  const [loading, setLoading] = useState(false); // Loading state
  const [currentResponse, setCurrentResponse] = useState(""); // Holds the word-by-word response
  const [responseWords, setResponseWords] = useState<string[]>([]); // Split GPT-4 response into words
  const [wordIndex, setWordIndex] = useState(0); // Tracks the current word being shown
  const bottomRef = useRef<HTMLDivElement>(null); // Reference to the bottom of the chat container
  const [history, setHistory] = useState<
    {
      id: string;
      title: string;
      messages: { text: string; isUser: boolean }[];
    }[]
  >([]);
  // Auto-scroll to the bottom of the chat container
  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, currentResponse]);

  // Auto-save chat to localStorage whenever `messages` updates
  useEffect(() => {
    const saveChatHistory = () => {
      const title = `${messages[0].text} ${new Date().toLocaleString()}`;
      const savedHistory = localStorage.getItem("chatHistory");
      const parsedHistory = savedHistory ? JSON.parse(savedHistory) : [];
      const updatedHistory = parsedHistory.filter(
        (item: any) => item.id !== "current"
      ); // Remove any existing "current" session
      const newHistory = [
        ...updatedHistory,
        { id: "current", title, messages },
      ];
      localStorage.setItem("chatHistory", JSON.stringify(newHistory));
    };

    if (messages.length > 0) {
      saveChatHistory();
    }
  }, [messages]);

  // Load saved chat on component mount
  useEffect(() => {
    const savedHistory = localStorage.getItem("chatHistory");
    if (savedHistory) {
      const parsedHistory = JSON.parse(savedHistory);
      const currentChat = parsedHistory.find(
        (item: any) => item.id === "current"
      );
      if (currentChat) {
        setMessages(currentChat.messages);
      }
    }
  }, []);

  // Typing effect for GPT-4 responses
  useEffect(() => {
    if (responseWords.length > 0 && wordIndex < responseWords.length) {
      const typingTimeout = setTimeout(() => {
        setCurrentResponse((prev) => prev + " " + responseWords[wordIndex]);
        setWordIndex((prevIndex) => prevIndex + 1);
      }, 100);

      return () => clearTimeout(typingTimeout);
    }

    if (wordIndex >= responseWords.length && responseWords.length > 0) {
      setMessages((prev) => [
        ...prev,
        { text: currentResponse.trim(), isUser: false },
      ]);
      setResponseWords([]);
      setWordIndex(0);
      setCurrentResponse("");
    }
  }, [responseWords, wordIndex, currentResponse]);

  const MAX_CONTEXT_TOKENS = 1000; // Tokens reserved for context
  // Handles sending a user message and keeping context
  const handleSend = async (message: string) => {
    setMessages((prev) => [...prev, { text: message, isUser: true }]);
    setLoading(true);

    try {
      const formattedMessages = [
        { role: "system", content },
        ...messages.map((msg) => ({
          role: msg.isUser ? "user" : "assistant",
          content: msg.text,
        })),
        { role: "user", content: message },
      ];

      const truncatedMessages = truncateMessages(
        formattedMessages,
        MAX_CONTEXT_TOKENS
      );
      const response = await fetchOpenAIResponse(truncatedMessages);
      setResponseWords(response.split(" "));
    } catch (error) {
      setMessages((prev) => [
        ...prev,
        { text: "Error: Unable to fetch response from OpenAI.", isUser: false },
      ]);
    } finally {
      setLoading(false);
    }
  };

  const handleEditMessage = async (index: number, newText: string) => {
    setLoading(true);

    try {
      // Keep all messages above the edited message
      const updatedMessages = messages.slice(0, index + 1).map((msg, i) => {
        if (i === index) {
          return { ...msg, text: newText }; // Update the edited message
        }
        return msg; // Keep other messages unchanged
      });

      setMessages(updatedMessages); // Update state with truncated messages

      // Prepare conversation history for GPT-4
      const formattedMessages = [
        { role: "system", content },
        ...updatedMessages.map((msg) => ({
          role: msg.isUser ? "user" : "assistant",
          content: msg.text,
        })),
      ];

      // Trigger a new request starting from the edited message
      const response = await fetchOpenAIResponse(formattedMessages);

      // Add the GPT-4 response to the updated messages
      setMessages((prev) => [...prev, { text: response, isUser: false }]);
    } catch (error) {
      setMessages((prev) => [
        ...prev,
        { text: "Error: Unable to fetch response from OpenAI.", isUser: false },
      ]);
    } finally {
      setLoading(false);
    }
  };
  const loadHistory = (history: { text: string; isUser: boolean }[]) => {
    console.log(history);
    setMessages(history);
  };

  // Restart conversation and save the previous session
  const handleNewConversation = () => {
    if (messages.length > 0) {
      const previousTitle = `${
        messages[0].text
      } ${new Date().toLocaleString()}`;
      const updatedHistory = [
        ...history.filter((item) => item.id !== "current"),
        { id: Date.now().toString(), title: previousTitle, messages },
      ];
      setHistory(updatedHistory);
      localStorage.setItem("chatHistory", JSON.stringify(updatedHistory));
    }

    setMessages([]); // Reset the messages for a new conversation
  };
  // Render the chat window
  return (
    <div className={styles.chatContainer}>
      <button
        onClick={() => {
          handleNewConversation();
        }}
        className={styles.newButton}
      >
        <FontAwesomeIcon icon={faPenToSquare} /> {/* Send icon */}
      </button>
      <ChatHistory
        handleNewConversation={handleNewConversation}
        onSelectHistory={loadHistory}
      />

      <div className={styles.chatWindow}>
        <div
          className={`${styles.messageContainer} ${
            messages.length === 0 && styles.empty
          }`}
        >
          {messages.map((msg, index) => (
            <ChatMessage
              key={index}
              text={msg.text}
              isUser={msg.isUser}
              onEdit={
                msg.isUser
                  ? (newText) => handleEditMessage(index, newText)
                  : undefined
              }
            />
          ))}
          {currentResponse.length > 0 && (
            <ChatMessage text={currentResponse} isUser={false} />
          )}
          {messages.length === 0 && <h1>Hvat eg hjálpa tær við?</h1>}
          {loading && <Loader />}
          <div ref={bottomRef} /> {/* Reference to scroll to the bottom */}
        </div>
        <ChatInput onSend={handleSend} />
      </div>
    </div>
  );
};

export default ChatWindow;
