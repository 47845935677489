import axios from "axios";

const API_URL = "https://api.openai.com/v1/chat/completions";

/**
 * Fetches a response from the OpenAI API using GPT-4 with full context.
 * @param messages - The conversation history, formatted as an array of objects.
 * @returns A string response from GPT-4.
 */
export const fetchOpenAIResponse = async (
  messages: { role: string; content: string }[]
): Promise<string> => {
  const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

  try {
    const response = await axios.post(
      API_URL,
      {
        model: "gpt-4o",
        messages: messages, // Include system and conversation history
        max_tokens: 800, // Approx. 300 words (since 1 word ≈ 1.33 tokens on average)
        temperature: 0.7, // Control randomness
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
      }
    );

    return response.data.choices[0].message.content;
  } catch (error: any) {
    console.error("OpenAI API Error:", error.response?.data || error.message);
    throw new Error("Failed to fetch response from OpenAI.");
  }
};
