import React from "react";
import styles from "../styles/Header.module.css";

const Header: React.FC = () => {
  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <img src="/assets/vita-logo-w-t.png" />
      </div>
      <nav className={styles.nav}>{/* <a href="#about">Um</a> */}</nav>
    </header>
  );
};

export default Header;
