import React, { useState } from "react";
import styles from "../styles/ChatWindow.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faSave } from "@fortawesome/free-solid-svg-icons";

interface ChatMessageProps {
  text: string;
  isUser: boolean;
  onEdit?: (newText: string) => void; // Callback to handle editing
}

const ChatMessage: React.FC<ChatMessageProps> = ({ text, isUser, onEdit }) => {
  const [isEditing, setIsEditing] = useState(false); // Edit mode state
  const [editText, setEditText] = useState(text); // Temporary edit text

  // Helper to render paragraphs and bullet points
  const renderContent = (content: string) => {
    const lines = content.split("\n");
    const elements: JSX.Element[] = [];
    let listItems: string[] = []; // Temporary storage for list items

    lines.forEach((line, index) => {
      const trimmedLine = line.trim();

      // Check for bullet points (e.g., -, *, 1.)
      if (/^[-*]\s/.test(trimmedLine)) {
        listItems.push(trimmedLine.replace(/^[-*]\s/, "")); // Remove the bullet marker
      } else if (/^\d+\.\s/.test(trimmedLine)) {
        listItems.push(trimmedLine.replace(/^\d+\.\s/, "")); // Handle numbered lists
      } else {
        // If it's not a list item, render previous list and start a new paragraph
        if (listItems.length > 0) {
          elements.push(
            <ul key={`list-${index}`}>
              {listItems.map((item, i) => (
                <li key={`item-${i}`}>{item}</li>
              ))}
            </ul>
          );
          listItems = []; // Clear the list
        }

        // Render the current line as a paragraph
        if (trimmedLine) {
          elements.push(<p key={`para-${index}`}>{trimmedLine}</p>);
        }
      }
    });

    // Render any remaining list items
    if (listItems.length > 0) {
      elements.push(
        <ul key="last-list">
          {listItems.map((item, i) => (
            <li key={`last-item-${i}`}>{item}</li>
          ))}
        </ul>
      );
    }

    return elements;
  };

  const handleSave = () => {
    if (onEdit) {
      onEdit(editText); // Call the parent's edit handler
    }
    setIsEditing(false); // Exit edit mode
  };

  const handleDiscard = () => {
    setEditText(text); // Reset the temporary text
    setIsEditing(false); // Exit edit mode
  };

  return (
    <div className={isUser ? styles.userMessage : styles.botMessage}>
      {!isUser ? (
        <img className={styles.logo} src="/assets/vita-logo.png" />
      ) : (
        ""
      )}
      {isEditing ? (
        <div className={styles.isEditing}>
          <textarea
            value={editText}
            onChange={(e) => setEditText(e.target.value)}
            className={styles.editInput}
          />
          <button onClick={handleDiscard} className={styles.discardButton}>
            Cancel
          </button>
          <button onClick={handleSave} className={styles.saveButton}>
            Save
          </button>
        </div>
      ) : (
        <div className={styles.notEditing}>
          {onEdit && (
            <button
              onClick={() => setIsEditing(true)}
              className={styles.editButton}
            >
              <FontAwesomeIcon icon={faPencil} /> {/* Send icon */}
            </button>
          )}
          {renderContent(text)}
        </div>
      )}
    </div>
  );
};

export default ChatMessage;
