import React, { useState } from "react";
import styles from "../styles/ChatWindow.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

interface ChatInputProps {
  onSend: (message: string) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSend }) => {
  const [input, setInput] = useState("");

  const handleSend = () => {
    if (input.trim()) {
      onSend(input);
      setInput("");
    }
  };

  return (
    <div className={styles.chatInputBlur}>
      <div className={styles.chatInput}>
        <input
          type="text"
          placeholder="Skriva tíni boð her..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSend()}
        />
        <button onClick={handleSend} className={styles.sendButton}>
          <FontAwesomeIcon icon={faPaperPlane} /> {/* Send icon */}
        </button>
      </div>
    </div>
  );
};

export default ChatInput;
