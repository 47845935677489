import React from "react";
import ChatWindow from "./components/ChatWindow";
import Header from "./components/Header";
import "./styles/globals.css";

const App: React.FC = () => {
  
  return (
    <>
      <Header />
      <ChatWindow />
    </>
  );
};

export default App;
