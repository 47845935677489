// src/utils/helpers.ts
import { encode } from "gpt-tokenizer";

/**
 * Format a user or bot message.
 */
export const formatMessage = (content: string, isUser: boolean) => {
  return {
    text: content,
    isUser,
  };
};

/**
 * Handle errors gracefully.
 */
export const handleApiError = (error: unknown): string => {
  console.error("API Error:", error);
  return "An error occurred. Please try again.";
};

/**
 * Truncate long text for display.
 */
export const truncateText = (text: string, maxLength: number = 100) => {
  if (text.length <= maxLength) return text;
  return `${text.substring(0, maxLength)}...`;
};

/**
 * Calculate the total token count for the conversation history.
 */
export const calculateTokenCount = (messages: { role: string; content: string }[]): number => {
  return messages.reduce((total, message) => total + encode(message.content).length, 0);
};

/**
 * Truncate the conversation history to fit within the token limit.
 */
export const truncateMessages = (
  messages: { role: string; content: string }[],
  maxContextTokens: number
): { role: string; content: string }[] => {
  let totalTokens = 0;
  const truncated: { role: string; content: string }[] = [];

  for (let i = messages.length - 1; i >= 0; i--) {
    const messageTokens = encode(messages[i].content).length;
    if (totalTokens + messageTokens > maxContextTokens) break;
    truncated.unshift(messages[i]);
    totalTokens += messageTokens;
  }

  return truncated;
};